<template>
    <section
    class="mx-5"
    >
        <v-row>
            <v-col
            cols="12"
            md="12"
            >
                <h2 class="headline">Configuración de grados</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            >
                                <v-select
                                v-model="$v.configCycle.jornada_id.$model"
                                :error-messages="jornadaErrors"
                                label="Jornada"
                                :items="jornadas"
                                item-text="nombre_jornada"
                                item-value="id_jornada"
                                :loading="loadJornada"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            >
                                <v-select
                                v-model="$v.configCycle.nivel_id.$model"
                                :error-messages="nivelErrors"
                                label="Nivel"
                                :items="levels"
                                item-text="nombre_nivel"
                                item-value="id_nivel"
                                :loading="loadLevel"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <section class="d-flex justify-center mt-4">
                            <div>
                                <v-btn
                                @click="get"
                                >
                                    Consultar
                                </v-btn>
                            </div>
                        </section>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col
            cols="12"
            >
                <v-card
                v-show="showConfigCycles"
                >
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar"
                                hide-details
                                single-line
                                >
                            </v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table
                        :headers="headers"
                        :items="configCycles"
                        :search="search"
                        >
                            <template v-slot:item.use_nivelacion="{ item }">
                                <td>{{parseNivelacion(item.use_nivelacion)}}</td>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-menu
                                bottom
                                left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                        @click="editItem(item)"
                                        v-can="'configcycles-edit'"
                                        >
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <template>
            <v-row justify="space-around">
                <v-col cols="auto">
                    <v-dialog
                    v-model="dialog"
                    transition="dialog-bottom-transition"
                    max-width="750"
                    >
                        <v-card>
                            <v-toolbar
                            color="primary"
                            dark
                            >
                                Editar
                            </v-toolbar>
                            <v-card-text>
                                <v-form>
                                    <v-row class="pt-2">
                                        <v-col
                                        cols="4"
                                        >
                                            <v-text-field
                                            label="Código grado"
                                            v-model="configCycle.grado_id"
                                            :error-messages="gradoIdErrors"
                                            :disabled="configCycle.id != null"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="8"
                                        >
                                            <v-text-field
                                            label="Nombre de grado"
                                            v-model="configCycle.nombre_grado"
                                            :error-messages="nombreGradoErrors"
                                            :disabled="configCycle.id != null"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                        cols="6"
                                        sm="4"
                                        md="3"
                                        >
                                            <v-checkbox
                                              v-model="configCycle.use_nivelacion"
                                              label="Aplica nivelación"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col
                                        cols="6"
                                        sm="8"
                                        md="9"
                                        >
                                            <v-text-field
                                            label="Nota mínima"
                                            v-model="configCycle.minimal_grade"
                                            :error-messages="minimalGradeErrors"
                                            :disabled="!configCycle.use_nivelacion"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                            label="Número de bimestres"
                                            v-model="configCycle.cycle_number"
                                            :error-messages="cycleNumberErrors"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="dialog = false"
                                >
                                    Cancelar
                                </v-btn>
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="submit"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </template>

        <v-fab-transition>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    color="pink"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    fixed
                    bottom
                    right
                    fab
                    @click="dialog = true"
                    v-show="showForm"
                    v-can="'configcycles-create'"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span>Agregar grado</span>
            </v-tooltip>
        </v-fab-transition>
    </section>
</template>

<script>
    import {mapMutations} from 'vuex'
    import { validationMixin } from 'vuelidate'
    import { required, numeric } from 'vuelidate/lib/validators'

    export default {
        mixins: [validationMixin],
        data() {
            return {
                dialog: false,
                jornadas: [],
                levels: [],
                loadJornada: false,
                loadLevel: false,
                showConfigCycles: false,
                search: '',
                headers: [
                    {
                        text: 'Jornada',
                        value: 'jornada.nombre_jornada'
                    },
                    {
                        text: 'Nivel',
                        value: 'nivel.nombre_nivel'
                    },
                    {
                        text: 'Grado',
                        value: 'grado.nombre'
                    },
                    {
                        text: 'Nivelación',
                        value: 'use_nivelacion'
                    },
                    {
                        text: 'Nota mínima',
                        value: 'jornada.minimal_grade'
                    },
                    {
                        text: 'Bimestres',
                        value: 'cycle_number'
                    },
                    {
                        text: 'Acciones',
                        value: 'actions',
                        width: '5%',
                        sortable: false
                    },
                ],
                configCycles: [],
                configCycle: {
                    id: null,
                    jornada_id: '',
                    nivel_id: '',
                    grado_id: '',
                    nombre_grado: '',
                    use_nivelacion: false,
                    minimal_grade: '',
                    cycle_number: ''
                }
            }
        },
        validations: {
            configCycle: {
                jornada_id: {required},
                nivel_id: {required},
                grado_id: {required},
                nombre_grado: {required},
                minimal_grade: {required,numeric},
                cycle_number: {required,numeric}
            }
        },
        computed: {
            showForm() {
                if(this.jornada != '' && this.level != ''){
                    return true
                }else{
                    return false
                }
            },
            jornadaErrors () {
                const errors = []
                if (!this.$v.configCycle.jornada_id.$dirty) return errors
                !this.$v.configCycle.jornada_id.required && errors.push('Elige la jornada')
                return errors
            },
            nivelErrors () {
                const errors = []
                if (!this.$v.configCycle.nivel_id.$dirty) return errors
                !this.$v.configCycle.nivel_id.required && errors.push('Elige el nivel')
                return errors
            },
            minimalGradeErrors () {
                const errors = []
                if (!this.$v.configCycle.minimal_grade.$dirty && this.configCycle.use_nivelacion) return errors
                !this.$v.configCycle.minimal_grade.required && this.configCycle.use_nivelacion && errors.push('Campo requerido')
                return errors
            },
            cycleNumberErrors () {
                const errors = []
                if (!this.$v.configCycle.cycle_number.$dirty) return errors
                !this.$v.configCycle.cycle_number.numeric && errors.push('El valor debe ser numérico')
                !this.$v.configCycle.cycle_number.required && errors.push('Campo requerido')
                return errors
            },
            gradoIdErrors () {
                const errors = []
                if (!this.$v.configCycle.grado_id.$dirty) return errors
                !this.$v.configCycle.grado_id.required && errors.push('Campo requerido')
                return errors
            },
            nombreGradoErrors () {
                const errors = []
                if (!this.$v.configCycle.nombre_grado.$dirty) return errors
                !this.$v.configCycle.nombre_grado.required && errors.push('Campo requerido')
                return errors
            },
        },
        methods: {
            ...mapMutations(['setLayout','setNotify','setLoading']),
            parseNivelacion(use_nivelacion) {
                return use_nivelacion == 1 ? 'Si' : 'No'
            },
            getDropdownsData(){
                this.loadJornada = true
                this.loadLevel = true

                this.axios.get('/getdropdownsdata')
                .then( (response) => {
                    this.loadJornada = false
                    this.loadLevel = false

                    this.jornadas = response.data.jornadas
                    this.levels = response.data.niveles
                })
                .catch( (error) => {
                    this.loadJornada = false
                    this.loadLevel = false
                    console.log(error)
                })
            },
            submit() {
                this.$v.$touch()

                if(!this.$v.configCycle.cycle_number.$invalid
                    && !this.configCycle.use_nivelacion.$invalid
                ){
                    console.log('si...')
                    if(this.configCycle.id == null){
                        console.log("id es null")
                        if(!this.$v.configCycle.grado_id.$invalid
                            && !this.$v.configCycle.nombre_grado.$invalid)
                        {
                            console.log('si grado y si nombre')
                            this.save()
                        }
                    }else{
                        console.log('id no es null')
                        this.update()
                    }
                }else{
                    console.log('no....')
                    if(!this.$v.configCycle.minimal_grade){
                        if(this.configCycle.id == null){
                            if(!this.$v.configCycle.grado_id.$invalid
                                && !this.$v.configCycle.nombre_grado.$invalid)
                            {
                                this.save()
                            }
                        }else{
                            this.update()
                        }
                    }
                }
            },
            get() {
                this.$v.$touch()

                if(!this.$v.configCycle.jornada_id.$invalid && !this.$v.configCycle.nivel_id.$invalid){
                    this.setLoading(true)

                    this.axios.get('/configcycles/'+this.configCycle.jornada_id+'/'+this.configCycle.nivel_id)
                    .then( (response) => {
                        this.setLoading(false)
                        this.configCycles = response.data.configCycles

                        if(this.configCycles.length > 0){
                            this.showConfigCycles = true
                        }else{
                            this.setNotify({'show':true,
                                            'color':'primary',
                                            'message':'No se encontró información',
                                            'time':5000})
                        }
                    })
                    .catch( (error) => {
                        this.setLoading(false)
                        console.log(error)
                    })
                }
            },
            editItem(item) {
                this.configCycle.id = item.id
                this.configCycle.grado_id = item.grado.id
                this.configCycle.nombre_grado = item.grado.nombre
                this.configCycle.use_nivelacion = item.use_nivelacion
                this.configCycle.minimal_grade = item.jornada.minimal_grade
                this.configCycle.cycle_number = item.cycle_number

                this.dialog = true
            },
            save() {
                this.setLoading(true)
                this.dialog = false

                this.axios
                .post('/configcycles',this.configCycle)
                .then( (response) => {
                    this.setNotify({
                                    'show':true,
                                    'color':'green',
                                    'message':response.data.message,
                                    'time':5300
                                });

                    this.setLoading(false)
                    this.get()
                })
                .catch( (error) => {
                    this.setLoading(false)
                    console.log(error)
                })
            },
            update() {
                this.setLoading(true)

                this.axios.put('/configcycles/'+this.configCycle.id,this.configCycle)
                .then( (response) => {
                    this.setLoading(false)

                    this.setNotify({'show':true,
                                    'color':'success',
                                    'message':response.data.message,
                                    'time':5000})

                    this.get()
                    this.dialog = false
                })
                .catch( (error) => {
                    this.dialog = false
                    this.setLoading(false)
                    console.log(error)
                })
            }
        },
        watch: {
            "dialog": function () {
                if(!this.dialog){
                    this.configCycle.id = null
                    this.configCycle.grado_id = null
                    this.configCycle.nombre_grado = ''
                    this.configCycle.use_nivelacion = false
                    this.configCycle.minimal_grade = ''
                    this.configCycle.cycle_number = ''
                }else{
                    this.$v.$reset()
                }
            }
        },
        created(){
            this.setLayout('main-layout')
            this.getDropdownsData()
        }
    }
</script>
