<template>
    <section
    class="mx-5"
    >
        <v-row>
            <v-col
            cols="12"
            md="12"
            >
                <h2 class="headline">Bancos</h2>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar banco"
                                hide-details
                                single-line
                                >
                            </v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table
                        :headers="headers"
                        :items="banks"
                        :search="search"
                        >
                            <template v-slot:item.actions="{ item }">
                                <v-menu
                                bottom
                                left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                        @click="editItem(item)"
                                        v-can="'banks-edit'"
                                        >
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                        @click="showAlert(item)"
                                        v-can="'banks-delete'"
                                        >
                                            <v-list-item-title>Eliminar</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- <template>
            <v-row justify="space-around">
                <v-col cols="auto">
                    <v-dialog
                    v-model="dialog"
                    transition="dialog-bottom-transition"
                    max-width="750"
                    >
                        <v-card>
                            <v-toolbar
                            color="primary"
                            dark
                            >
                                {{dialogTitle}}
                            </v-toolbar>
                            <v-card-text>
                                <v-form>
                                    <v-row class="pt-2">
                                        <v-col>
                                            <v-text-field
                                            label="Nombre"
                                            v-model="$v.bank.name.$model"
                                            :error-messages="nameErrors"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-select
                                            label="Cuenta contable"
                                            :items="accounts"
                                            item-text="description"
                                            item-value="id"
                                            v-model="$v.bank.account_id.$model"
                                            :error-messages="accountIdErrors"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="dialog = false"
                                >
                                    Cancelar
                                </v-btn>
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="submit"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </template> -->

        <v-fab-transition>
            <v-btn
            color="pink"
            dark
            fixed
            bottom
            right
            fab
            @click="dialog = true"
            v-can="'banks-create'"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-fab-transition>

        <QuestionAlert
        :alert="onAlert"
        question="¿Esta seguro de eliminar el banco?"
        @close="onAlert = false"
        @execute="deleteItem"
        />

        <Modal
        :dialog="dialog"
        :title="dialogTitle"
        @close="dialog = false"
        @execute="submit"
        >
            <template v-slot>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field
                            label="Nombre"
                            v-model="$v.bank.name.$model"
                            :error-messages="nameErrors"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-combobox
                            label="Cuenta contable"
                            :items="accounts"
                            item-text="description"
                            item-value="id"
                            v-model="$v.bank.account.$model"
                            :error-messages="accountErrors"
                            >
                            </v-combobox>
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </Modal>
    </section>
</template>

<script>
import {mapMutations} from "vuex"
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import QuestionAlert from '@/components/QuestionAlert.vue'
import Modal from '@/components/Modal.vue'

export default {
    mixins: [validationMixin],
    data(){
        return {
            onAlert: false,
           search: '',
           headers: [
               {
                   text: 'Nombre',
                   value: 'name'
               },
               {
                   text: 'Cuenta contable',
                   value: 'account.description'
               },
               {
                   text: 'Acciones',
                   value: 'actions',
                   width: '5%',
                   sortable: false
               },
           ],
           banks: [],
           bank: {
               id: null,
               account: null,
               name: '',
               status: null,
           },
           accounts: [],
           dialog: false,
           itemToDelete: null,
           robj: false
       }
    },
    validations: {
        bank: {
            account: {required},
            name: {required}
        },
    },
    computed: {
        dialogTitle() {
            return (this.bank.id != null) ? "Editar banco" : "Nuevo banco"
        },
        accountErrors () {
            const errors = []
            if (!this.$v.bank.account.$dirty) return errors
            !this.$v.bank.account.required && errors.push('Campo requerido')
            return errors
        },
        nameErrors () {
            const errors = []
            if (!this.$v.bank.name.$dirty) return errors
            !this.$v.bank.name.required && errors.push('Campo requerido')
            return errors
        },
    },
   methods: {
        ...mapMutations(['setLayout','setNotify','setLoading']),
        get() {
            this.setLoading(true)

            this.axios.get('/banks')
            .then( (response) => {
                this.setLoading(false)

                this.banks = response.data.banks
                this.accounts = response.data.accounts
            })
            .catch( (error) => {
                this.setLoading(false)
                console.log(error)
            })
        },
        submit() {
            this.$v.$touch()

            if(!this.$v.$invalid){
                if(this.bank.id == null){
                    this.save()
                }else{
                    this.update()
                }
            }
        },
        save() {
            this.setLoading(true)

            this.axios.post('/banks',this.bank)
            .then( (response) => {
                this.setLoading(false)
                this.get()
                this.dialog = false

                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})
            })
            .catch( (error) => {
                this.dialog = false
                this.setLoading(false)
                console.log(error)
            })
        },
        editItem(item){
            this.bank.id = item.id
            this.bank.account_id = item.account_id
            this.bank.name = item.name
            this.bank.status = item.status
            this.dialog = true
        },
        update(){
            this.setLoading(true)

            this.axios.put('/banks/'+this.bank.id,this.bank)
            .then( (response) => {
                this.setLoading(false)

                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})

                this.get()
                this.dialog = false
            })
            .catch( (error) => {
                this.dialog = false
                this.setLoading(false)
                console.log(error)
            })
        },
        deleteItem() {
            this.onAlert = false
            this.setLoading(true)

            this.axios.delete('/banks/'+this.itemToDelete.id)
            .then( (response) => {
                this.itemToDelete = null
                this.setLoading(false)
                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})
                this.get()
            })
            .catch( (error) => {
                this.setLoading(false)
                console.log(error)
            })
        },
        showAlert(item) {
            this.itemToDelete = item
            this.onAlert = true
        }
    },
    created(){
        this.setLayout('main-layout')
    },
    mounted(){
        this.get()
    },
    watch: {
        "dialog": function () {
            if(!this.dialog){
                this.bank.id = null
                this.bank.name = ''
                this.bank.account = ''
                this.bank.status = ''
                this.$v.$reset()
            }
        },
        "bank.name": function() {
            console.log(this.bank)
        }
    },
    components: {
        QuestionAlert,
        Modal
    }
}
</script>
