<template>
    <section
    class="mx-5"
    >
        <v-row>
            <v-col
            cols="12"
            md="12"
            >
                <h2 class="headline">Familias de productos</h2>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar usuario"
                                hide-details
                                single-line
                                >
                            </v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table
                        :headers="headers"
                        :items="families"
                        :search="search"
                        >
                            <template v-slot:item.actions="{ item }">
                                <v-menu
                                bottom
                                left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                        @click="editItem(item)"
                                        v-can="'families-edit'"
                                        >
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                        @click="showAlert(item)"
                                        v-can="'families-delete'"
                                        >
                                            <v-list-item-title>Eliminar</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-fab-transition>
            <v-btn
            color="pink"
            dark
            fixed
            bottom
            right
            fab
            @click="dialog = true"
            v-can="'families-create'"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-fab-transition>

        <!-- <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar
                dark
                color="primary"
                >
                    <v-btn
                    icon
                    dark
                    @click="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{dialogTitle}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        dark
                        text
                        @click="submit"
                        >
                            Guardar
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-text-field
                                label="Nombre"
                                v-model="$v.family.name.$model"
                                :error-messages="nameErrors"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                label="Famila padre"
                                :items="families"
                                :loading="autocompleteLoading"
                                item-text="name"
                                item-value="id"
                                v-model="family.parent_id"
                                :search-input.sync="searchParent"

                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-select
                                label="Familia padre"
                                :items="families"
                                item-text="name"
                                item-value="id"
                                v-model="family.parent_id"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog> -->

        <Modal
        :dialog="dialog"
        :title="dialogTitle"
        @close="dialog = false"
        @execute="submit"
        >
            <template v-slot>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field
                            label="Nombre"
                            v-model="$v.family.name.$model"
                            :error-messages="nameErrors"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- <v-row>
                        <v-col>
                            <v-autocomplete
                            label="Famila padre"
                            :items="families"
                            :loading="autocompleteLoading"
                            item-text="name"
                            item-value="id"
                            v-model="family.parent_id"
                            :search-input.sync="searchParent"

                            ></v-autocomplete>
                        </v-col>
                    </v-row> -->
                    <v-row>
                        <v-col>
                            <v-select
                            label="Familia padre"
                            :items="families"
                            item-text="name"
                            item-value="id"
                            v-model="family.parent_id"
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </Modal>

        <QuestionAlert
        :alert="onAlert"
        question="¿Esta seguro de eliminar la familia?"
        @close="onAlert = false"
        @execute="deleteItem"
        />
    </section>
</template>

<script>
import {mapMutations} from "vuex"
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import QuestionAlert from '@/components/QuestionAlert.vue'
import Modal from '@/components/Modal.vue'

export default {
    mixins: [validationMixin],
   data(){
       return {
           search: '',
           onAlert: false,
           headers: [
               {
                   text: 'Nombre',
                   value: 'name'
               },
               {
                   text: 'Familia padre',
                   value: 'parent_family.name'
               },
               {
                   text: 'Acciones',
                   value: 'actions',
                   width: '5%',
                   sortable: false
               },
           ],
           families: [],
           family: {
               id: null,
               name: '',
               parent_id: null
           },
           dialog: false,
           autocompleteLoading: false,
           searchParent: null,
           items:[],
           itemToDelete: null
       }
   },
   validations: {
       family: {
           name: {required}
       },
   },
   computed: {
       dialogTitle() {
           return (this.family.id != null) ? "Editar familia" : "Nuevo familia"
       },
       nameErrors () {
           const errors = []
           if (!this.$v.family.name.$dirty) return errors
           !this.$v.family.name.required && errors.push('Campo requerido')
           return errors
       },
   },
   methods: {
        ...mapMutations(['setLayout','setNotify','setLoading']),
        get() {
            this.setLoading(true)

            this.axios.get('/families')
            .then( (response) => {
                this.setLoading(false)

                this.families = response.data.families
            })
            .catch( (error) => {
                this.setLoading(false)
                console.log(error)
            })
        },
        submit() {
            this.$v.$touch()

            if(!this.$v.$invalid){
                if(this.family.id == null){
                    this.save()
                }else{
                    this.update()
                }
            }
        },
        save() {
            this.setLoading(true)

            this.axios.post('/families',this.family)
            .then( (response) => {
                this.family.id = null
                this.family.name = ''
                this.family.parent_id = null
                // this.family.email = ''

                this.setLoading(false)
                this.get()
                this.dialog = false

                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})
            })
            .catch( (error) => {
                this.dialog = false
                this.setLoading(false)
                console.log(error)
            })
        },
        editItem(item){
            this.family.id = item.id
            this.family.name = item.name
            this.family.parent_id = item.parent_id
            this.dialog = true
        },
        update(){
            this.setLoading(true)

            this.axios.put('/families/'+this.family.id,this.family)
            .then( (response) => {
                this.family.id = ''
                this.family.name = null
                this.family.parent_id = null

                this.setLoading(false)

                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})

                this.get()
                this.dialog = false
            })
            .catch( (error) => {
                this.dialog = false
                this.setLoading(false)
                console.log(error)
            })
        },
        deleteItem() {
            this.onAlert = false
            this.setLoading(true)

            this.axios.delete('/families/'+this.itemToDelete.id)
            .then( (response) => {
                this.itemToDelete = null
                this.setLoading(false)
                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})
                this.get()
            })
            .catch( (error) => {
                this.setLoading(false)
                console.log(error)
            })
        },
        showAlert(item) {
            this.itemToDelete = item
            this.onAlert = true
        },
        closeDialog() {
            this.dialog = false
        }
    },
    created(){
        this.setLayout('main-layout')
    },
    mounted(){
        this.get()
    },
    watch: {
        "dialog": function () {
            if(!this.dialog){
                this.family.id = null
                this.family.name = ''
                this.$v.$reset()
            }
        },
        searchParent(){
            this.autocompleteLoading = true

            this.axios.post('/families/search',this.searchParent)
            .then( (response) => {
                this.autocompleteLoading = false

                this.items = response.data.parents
            })
            .catch( (error) => {
                this.autocompleteLoading = false
                console.log(error)
            })
        }
    },
    components: {
        QuestionAlert,
        Modal
    }
}
</script>
