<template>
    <section
    class="mx-5"
    >
        <v-row>
            <v-col
            cols="12"
            md="12"
            >
                <h2 class="headline">Plantillas (Aranceles)</h2>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar arancel"
                                hide-details
                                single-line
                                >
                            </v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table
                        :headers="headers"
                        :items="templates"
                        :search="search"
                        >
                            <template v-slot:item.actions="{ item }">
                                <v-menu
                                bottom
                                left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                        @click="editItem(item)"
                                        v-can="'templates-edit'"
                                        >
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                        @click="showAlert(item)"
                                        v-can="'templates-delete'"
                                        >
                                            <v-list-item-title>Eliminar</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <template>
            <v-row justify="space-around">
                <v-col cols="auto">
                    <v-dialog
                    v-model="dialog"
                    transition="dialog-bottom-transition"
                    max-width="750"
                    >
                        <v-card>
                            <v-toolbar
                            color="primary"
                            dark
                            >
                                {{dialogTitle}}
                            </v-toolbar>
                            <v-card-text>
                                <v-form>
                                    <v-row class="pt-2">
                                        <v-col
                                        cols="3"
                                        >
                                            <v-select
                                            label="Tipo"
                                            :items="types"
                                            item-text="description"
                                            item-value="id"
                                            v-model="$v.type.$model"
                                            :error-messages="typeErrors"
                                            >
                                            </v-select>
                                        </v-col>
                                        <v-col
                                        >
                                            <v-select
                                            :label="typeName"
                                            :items="typeItems"
                                            item-text="name"
                                            item-value="id"
                                            v-model="$v.template.templetable_id.$model"
                                            :error-messages="templetableIdErrors"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-select
                                            label="Cuenta contable"
                                            :items="accounts"
                                            item-text="description"
                                            item-value="id"
                                            v-model="$v.template.account_id.$model"
                                            :error-messages="accountIdErrors"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                        cols="6"
                                        >
                                            <v-text-field
                                            label="Precio"
                                            v-model="$v.template.price.$model"
                                            :error-messages="priceErrors"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="6"
                                        >
                                            <v-text-field
                                            label="Precio combo"
                                            v-model="$v.template.group_price.$model"
                                            :error-messages="groupPriceErrors"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="dialog = false"
                                >
                                    Cancelar
                                </v-btn>
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="submit"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </template>

        <v-fab-transition>
            <v-btn
            color="pink"
            dark
            fixed
            bottom
            right
            fab
            @click="dialog = true"
            v-can="'templates-create'"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-fab-transition>

        <QuestionAlert
        :alert="onAlert"
        question="¿Esta seguro de eliminar la marca?"
        @close="onAlert = false"
        @execute="deleteItem"
        />
    </section>
</template>

<script>
import {mapMutations} from "vuex"
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import QuestionAlert from '@/components/QuestionAlert.vue'

export default {
    mixins: [validationMixin],
    data(){
        return {
            onAlert: false,
            types: [
                    {'id': 1, 'description': 'Producto'},
                    {'id': 2, 'description': 'Servicio'}
                ],
            typeItems: [],
            type: '',
            services: [],
            products: [],
            search: '',
            headers: [
                {
                    text: 'Nombre',
                    value: 'templetable.name'
                },
                {
                    text: 'Cuenta contable',
                    value: 'account.description'
                },
                {
                    text: 'Precio',
                    value: 'price'
                },
                {
                    text: 'Precio combo',
                    value: 'group_price'
                },
                {
                    text: 'Acciones',
                    value: 'actions',
                    width: '5%',
                    sortable: false
                },
            ],
            templates: [],
            template: {
                id: null,
                account_id: null,
                price: '',
                group_price: '',
                templetable_id: null,
                templetable_type: '',
                status: null,
            },
            accounts: [],
            dialog: false
       }
    },
    validations: {
        type: {required},
        template: {
            account_id: {required},
            price: {required},
            group_price: {required},
            templetable_id: {required}
        }
    },
    computed: {
        dialogTitle() {
            return (this.template.id != null) ? "Editar arancel" : "Nuevo arancel"
        },
        typeName() {
            return (this.type == 1) ? 'Producto' : 'Servicio'
        },
        typeErrors () {
            const errors = []
            if (!this.$v.type.$dirty) return errors
            !this.$v.type.required && errors.push('Campo requerido')
            return errors
        },
        accountIdErrors () {
            const errors = []
            if (!this.$v.template.account_id.$dirty) return errors
            !this.$v.template.account_id.required && errors.push('Campo requerido')
            return errors
        },
        priceErrors () {
            const errors = []
            if (!this.$v.template.price.$dirty) return errors
            !this.$v.template.price.required && errors.push('Campo requerido')
            return errors
        },
        groupPriceErrors () {
            const errors = []
            if (!this.$v.template.group_price.$dirty) return errors
            !this.$v.template.group_price.required && errors.push('Campo requerido')
            return errors
        },
        templetableIdErrors () {
            const errors = []
            if (!this.$v.template.templetable_id.$dirty) return errors
            !this.$v.template.templetable_id.required && errors.push('Campo requerido')
            return errors
        }
    },
   methods: {
        ...mapMutations(['setLayout','setNotify','setLoading']),
        get() {
            this.setLoading(true)

            this.axios.get('/templates')
            .then( (response) => {
                this.setLoading(false)

                this.templates = response.data.templates
                this.accounts = response.data.accounts
                this.products = response.data.products
                this.services = response.data.services
            })
            .catch( (error) => {
                this.setLoading(false)
                console.log(error)
            })
        },
        submit() {
            this.$v.$touch()

            if(!this.$v.$invalid){
                if(this.template.id == null){
                    this.save()
                }else{
                    this.update()
                }
            }
        },
        save() {
            this.setLoading(true)

            this.axios.post('/templates',{
                'template': this.template,
                'type': this.type
            })
            .then( (response) => {
                this.setLoading(false)
                this.get()
                this.dialog = false

                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})
            })
            .catch( (error) => {
                this.dialog = false
                this.setLoading(false)
                console.log(error)
            })
        },
        editItem(item){
            this.template.id = item.id
            this.template.account_id = item.account_id
            this.template.price = item.price
            this.template.group_price = item.group_price
            this.template.templetable_id = item.templetable_id
            this.template.templetable_type = item.templetable_type
            this.template.status = item.status
            this.type = (item.templetable_type == 'App\\Product') ? 1 : 2

            this.dialog = true
        },
        update(){
            this.setLoading(true)

            this.axios.put('/templates/'+this.template.id,{
                'template': this.template,
                'type': this.type
            })
            .then( (response) => {
                this.setLoading(false)

                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})

                this.get()
                this.dialog = false
            })
            .catch( (error) => {
                this.dialog = false
                this.setLoading(false)
                console.log(error)
            })
        },
        showAlert(item) {
            this.itemToDelete = item
            this.onAlert = true
        },
        deleteItem() {
            this.onAlert = false
            this.setLoading(true)

            this.axios.delete('/templates/'+this.itemToDelete.id)
            .then( (response) => {
                this.itemToDelete = null
                this.setLoading(false)
                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})
                this.get()
            })
            .catch( (error) => {
                this.setLoading(false)
                console.log(error)
            })
        },
        closeDialog() {
            this.dialog = false
        },
    },
    created(){
        this.setLayout('main-layout')
    },
    mounted(){
        this.get()
    },
    watch: {
        "dialog": function () {
            if(!this.dialog){
                this.template.id = null
                this.template.account_id = null
                this.template.price = ''
                this.template.group_price = ''
                this.template.templetable_id = null
                this.template.templetable_type = ''
                this.template.status = ''
                this.$v.$reset()
            }
        },
        "type": function () {
            this.typeItems = (this.type == 1) ? this.products : this.services
        }
    },
    components: {
        QuestionAlert
    }
}
</script>
