<template>
    <section
    class="mx-5"
    >
        <v-row>
            <v-col
            cols="12"
            md="12"
            >
                <h2 class="headline">Cuentas contables</h2>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                hide-details
                                single-line
                                >
                            </v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table
                        :headers="headers"
                        :items="accounts"
                        :search="search"
                        >
                            <template v-slot:item.actions="{ item }">
                                <v-menu
                                bottom
                                left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                        @click="edit(item)"
                                        v-can="'accounts-edit'"
                                        >
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                        @click="showAlert(item)"
                                        v-can="'accounts-delete'"
                                        >
                                            <v-list-item-title>Eliminar</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-fab-transition>
            <v-btn
            color="pink"
            dark
            fixed
            bottom
            right
            fab
            @click="dialog = true"
            v-can="'accounts-create'"
            >
            <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-fab-transition>

        <Modal
        :dialog="dialog"
        :title="dialogTitle"
        @close="dialog = false"
        @execute="submit"
        >
            <template v-slot>
                <v-row
                justify="center"
                >
                    <v-col
                    cols="12"
                    >
                        <v-form>
                            <v-text-field
                            label="Descripción"
                            v-model="$v.account.description.$model"
                            :error-messages="descriptionErrors"
                            ></v-text-field>
                            <v-text-field
                            label="Código"
                            v-model="$v.account.code.$model"
                            :error-messages="codeErrors"
                            ></v-text-field>
                            <v-select
                            label="Grupo"
                            :items="groups"
                            item-text="description"
                            item-value="id"
                            v-model="$v.account.group_id.$model"
                            :error-messages="groupIdErrors"
                            ></v-select>
                            <v-select
                            label="Naturaleza de la cuenta"
                            :items="natureAccounts"
                            item-text="name"
                            item-value="id"
                            v-model="$v.account.nature_account.$model"
                            :error-messages="natureAccountErrors"
                            ></v-select>
                            <v-select
                            label="Tipo de cuenta"
                            :items="typeAccounts"
                            item-text="name"
                            item-valu="id"
                            v-model="$v.account.type.$model"
                            :error-messages="typeErrors"
                            ></v-select>
                            <v-combobox
                            label="Cuenta padre"
                            :items="accounts"
                            item-text="description"
                            item-value="id"
                            v-model="account.parent_id"
                            >
                            </v-combobox>
                        </v-form>
                    </v-col>
                </v-row>
            </template>
        </Modal>

        <QuestionAlert
        :alert="onAlert"
        question="¿Esta seguro de eliminar la cuenta contable?"
        @close="closeAlert"
        @execute="deleteAccount"
        />
    </section>
</template>

<script>
    import {mapMutations} from "vuex"
    import { validationMixin } from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'

    import QuestionAlert from '@/components/QuestionAlert.vue'
    import Modal from '@/components/Modal.vue'

    export default {
        mixins: [validationMixin],
        data() {
            return {
                onAlert: false,
                headers: [
                    {
                        text: 'Codigo',
                        value: 'code'
                    },
                    {
                        text: 'Descripcion',
                        value: 'description'
                    },
                    {
                        text: 'Clase',
                        value: 'nature_account'
                    },
                    {
                        text: 'Tipo',
                        value: 'type'
                    },
                    {
                        text: 'Grupo',
                        value: 'account_group.description'
                    },
                    {
                        text: 'Acciones',
                        value: 'actions',
                        sortable: false,
                        width: '5%',
                    },
                ],
                search: '',
                accounts: [],
                dialog: false,
                account: {
                    id: null,
                    parent_id: null,
                    code: '',
                    group_id: null,
                    nature_account: '',
                    type: '',
                    description: ''
                },
                groups: [],
                natureAccounts: [
                    {
                        'id': 'Deudora',
                        'name': 'Deudora'
                    },
                    {
                        'id': 'Acreedora',
                        'name': 'Acreedora'
                    }
                ],
                typeAccounts: [
                    {
                        'id': 'General',
                        'name': 'General'
                    },
                    {
                        'id': 'Detalle',
                        'name': 'Detalle'
                    }
                ],
                itemToDelete: null
            }
        },
        validations: {
            account: {
                code: {required},
                group_id: {required},
                nature_account: {required},
                type: {required},
                description: {required}
            }
        },
        computed: {
            dialogTitle() {
                return (this.account.id != null) ? "Editar cuenta" : "Nueva cuenta"
            },
            parentIdErrors () {
                const errors = []
                if (!this.$v.account.parent_id.$dirty) return errors
                !this.$v.account.parent_id.required && errors.push('Campo requerido')
                return errors
            },
            codeErrors () {
                const errors = []
                if (!this.$v.account.code.$dirty) return errors
                !this.$v.account.code.required && errors.push('Campo requerido')
                return errors
            },
            groupIdErrors () {
                const errors = []
                if (!this.$v.account.group_id.$dirty) return errors
                !this.$v.account.group_id.required && errors.push('Campo requerido')
                return errors
            },
            natureAccountErrors () {
                const errors = []
                if (!this.$v.account.nature_account.$dirty) return errors
                !this.$v.account.nature_account.required && errors.push('Campo requerido')
                return errors
            },
            typeErrors () {
                const errors = []
                if (!this.$v.account.type.$dirty) return errors
                !this.$v.account.type.required && errors.push('Campo requerido')
                return errors
            },
            descriptionErrors () {
                const errors = []
                if (!this.$v.account.description.$dirty) return errors
                !this.$v.account.description.required && errors.push('Campo requerido')
                return errors
            }
        },
        methods: {
            ...mapMutations(['setLayout','setNotify','setLoading']),
            get() {
                this.setLoading(true)

                this.axios.get('/accounts')
                .then( (response) => {
                    this.setLoading(false)

                    this.accounts = response.data.accounts
                    this.groups = response.data.groups
                })
                .catch( (error) => {
                    this.setLoading(false)
                    console.log(error)
                })
            },
            submit() {
                this.$v.$touch()

                if(!this.$v.$invalid){
                    if(this.account.id == null){
                        this.save()
                    }else{
                        this.update()
                    }
                }
            },
            save() {
                this.setLoading(true)

                this.axios.post('/accounts',this.account)
                .then( (response) => {
                    this.account.description = ''
                    this.account.code = ''
                    this.account.parent_id = null
                    this.account.id = null

                    this.setLoading(false)
                    this.get()
                    this.dialog = false

                    this.setNotify({'show':true,
									'color':'success',
									'message':response.data.message,
									'time':5000})
                })
                .catch( (error) => {
                    this.dialog = false
                    this.setLoading(false)
                    console.log(error)
                })
            },
            edit(item) {
                console.log(item)
                this.account.id = item.id
                this.account.description = item.description
                this.account.code = item.code
                this.account.group_id = item.group_id
                this.account.nature_account = item.nature_account
                this.account.type = item.type
                this.account.parent_id = item.parent_id
                this.dialog = true
                console.log(this.account)
            },
            update() {
                this.setLoading(true)

                this.axios.put('/accounts/'+this.account.id,this.account)
                .then( (response) => {
                    this.account.description = ''
                    this.account.code = ''
                    this.account.parent_id = null
                    this.account.id = null

                    this.setLoading(false)
                    this.get()
                    this.dialog = false

                    this.setNotify({'show':true,
									'color':'success',
									'message':response.data.message,
									'time':5000})
                })
                .catch( (error) => {
                    this.dialog = false
                    this.setLoading(false)
                    console.log(error)
                })
            },
            deleteAccount() {
                this.onAlert = false
                this.setLoading(true)

                this.axios.delete('/accounts/'+this.itemToDelete.id)
                .then( (response) => {
                    this.itemToDelete = false
                    this.setLoading(false)
                    this.setNotify({'show':true,
                                    'color':'success',
                                    'message':response.data.message,
                                    'time':5000})
                    this.get()
                })
                .catch( (error) => {
                    this.setLoading(false)
                    console.log(error)
                })
            },
            closeDialog() {
                this.dialog = false
            },
            closeAlert() {
                this.itemToDelete = null
                this.onAlert = false
            },
            showAlert(item) {
                this.itemToDelete = item
                this.onAlert = true
            }
        },
        created(){
            this.setLayout('main-layout')
        },
        mounted(){
            this.get()
        },
        watch: {
            "dialog": function () {
                if(!this.dialog){
                    this.account.description = ''
                    this.account.code = ''
                    this.account.parent_id = null
                    this.account.id = null
                }
            },
        },
        components: {
            QuestionAlert,
            Modal
        }
    }
</script>
