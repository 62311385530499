<template>
    <section
    class="mx-5"
    >
        <v-row>
            <v-col
            cols="12"
            md="12"
            >
                <h2 class="headline">Servicios</h2>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar servicio"
                                hide-details
                                single-line
                                >
                            </v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table
                        :headers="headers"
                        :items="services"
                        :search="search"
                        >
                            <template v-slot:item.actions="{ item }">
                                <v-menu
                                bottom
                                left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                        @click="editItem(item)"
                                        v-can="'services-edit'"
                                        >
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                        @click="showAlert(item)"
                                        v-can="'services-delete'"
                                        >
                                            <v-list-item-title>Eliminar</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <template>
            <v-row justify="space-around">
                <v-col cols="auto">
                    <v-dialog
                    v-model="dialog"
                    transition="dialog-bottom-transition"
                    max-width="750"
                    >
                        <v-card>
                            <v-toolbar
                            color="primary"
                            dark
                            >
                                {{dialogTitle}}
                            </v-toolbar>
                            <v-card-text>
                                <v-form>
                                    <v-row class="pt-2">
                                        <v-col>
                                            <v-text-field
                                            label="Nombre"
                                            v-model="$v.service.name.$model"
                                            :error-messages="nameErrors"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="dialog = false"
                                >
                                    Cancelar
                                </v-btn>
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="submit"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </template>

        <v-fab-transition>
            <v-btn
            color="pink"
            dark
            fixed
            bottom
            right
            fab
            @click="dialog = true"
            v-can="'services-create'"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-fab-transition>

        <QuestionAlert
        :alert="onAlert"
        question="¿Esta seguro de eliminar el servicio?"
        @close="onAlert = false"
        @execute="deleteItem"
        />
    </section>
</template>

<script>
import {mapMutations} from "vuex"
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import QuestionAlert from '@/components/QuestionAlert.vue'

export default {
    mixins: [validationMixin],
    data(){
        return {
            onAlert: false,
           search: '',
           headers: [
               {
                   text: 'Nombre',
                   value: 'name'
               },
               {
                   text: 'Estado',
                   value: 'status'
               },
               {
                   text: 'Acciones',
                   value: 'actions',
                   width: '5%',
                   sortable: false
               },
           ],
           services: [],
           service: {
               id: null,
               name: '',
               status: null,
           },
           dialog: false,
           itemToDelete: null
       }
    },
    validations: {
        service: {
            name: {required}
        }
    },
    computed: {
        dialogTitle() {
            return (this.service.id != null) ? "Editar servicio" : "Nuevo servicio"
        },
        nameErrors () {
            const errors = []
            if (!this.$v.service.name.$dirty) return errors
            !this.$v.service.name.required && errors.push('Campo requerido')
            return errors
        },
    },
   methods: {
        ...mapMutations(['setLayout','setNotify','setLoading']),
        get() {
            this.setLoading(true)

            this.axios.get('/services')
            .then( (response) => {
                this.setLoading(false)

                this.services = response.data
            })
            .catch( (error) => {
                this.setLoading(false)
                console.log(error)
            })
        },
        submit() {
            this.$v.$touch()

            if(!this.$v.$invalid){
                if(this.service.id == null){
                    this.save()
                }else{
                    this.update()
                }
            }
        },
        save() {
            this.setLoading(true)

            this.axios.post('/services',this.service)
            .then( (response) => {
                this.service.id = null
                this.service.name = ''
                this.service.status = ''

                this.setLoading(false)
                this.get()
                this.dialog = false

                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})
            })
            .catch( (error) => {
                this.dialog = false
                this.setLoading(false)
                console.log(error)
            })
        },
        editItem(item){
            this.service.id = item.id
            this.service.name = item.name
            this.service.status = item.status
            this.dialog = true
        },
        update(){
            this.setLoading(true)

            this.axios.put('/services/'+this.service.id,this.service)
            .then( (response) => {
                this.service.id = null
                this.service.name = ''
                this.service.status = ''

                this.setLoading(false)

                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})

                this.get()
                this.dialog = false
            })
            .catch( (error) => {
                this.dialog = false
                this.setLoading(false)
                console.log(error)
            })
        },
        showAlert(item) {
            this.itemToDelete = item
            this.onAlert = true
        },
        deleteItem() {
            this.onAlert = false
            this.setLoading(true)

            this.axios.delete('/services/'+this.itemToDelete.id)
            .then( (response) => {
                this.itemToDelete = null
                this.setLoading(false)
                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})
                this.get()
            })
            .catch( (error) => {
                this.setLoading(false)
                console.log(error)
            })
        },
        closeDialog() {
            this.dialog = false
        }
    },
    created(){
        this.setLayout('main-layout')
    },
    mounted(){
        this.get()
    },
    watch: {
        "dialog": function () {
            if(!this.dialog){
                this.service.id = null
                this.service.name = ''
                this.service.status = ''
                this.$v.$reset()
            }
        }
    },
    components: {
        QuestionAlert
    }
}
</script>
