<template>
    <section
    class="mx-5"
    >
        <v-row>
            <v-col
            cols="12"
            md="12"
            >
                <h2 class="headline">Productos</h2>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar producto"
                                hide-details
                                single-line
                                >
                            </v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table
                        :headers="headers"
                        :items="products"
                        :search="search"
                        >
                            <template v-slot:item.actions="{ item }">
                                <v-menu
                                bottom
                                left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                        @click="editItem(item)"
                                        v-can="'products-edit'"
                                        >
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                        @click="showAlert(item)"
                                        v-can="'products-delete'"
                                        >
                                            <v-list-item-title>Eliminar</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <template>
            <v-row justify="space-around">
                <v-col cols="auto">
                    <v-dialog
                    v-model="dialog"
                    transition="dialog-bottom-transition"
                    max-width="750"
                    >
                        <v-card>
                            <v-toolbar
                            color="primary"
                            dark
                            >
                                {{dialogTitle}}
                            </v-toolbar>
                            <v-card-text>
                                <v-form>
                                    <v-row class="pt-2">
                                        <v-col>
                                            <v-text-field
                                            label="Nombre"
                                            v-model="$v.product.name.$model"
                                            :error-messages="nameErrors"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                        cols="6"
                                        >
                                            <v-select
                                            label="Familia"
                                            :items="familyProducts"
                                            item-text="name"
                                            item-value="id"
                                            v-model="$v.product.family_product_id.$model"
                                            :error-messages="familyProductIdErrors"
                                            >
                                            </v-select>
                                        </v-col>
                                        <v-col
                                        cols="6"
                                        >
                                            <v-select
                                            label="Sub familia"
                                            :items="subFamilyProducts"
                                            item-text="name"
                                            item-value="id"
                                            v-model="subFamily"
                                            :loading="loadSubfamily"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                        cols="4"
                                        >
                                            <v-select
                                            label="Categoría"
                                            :items="categoryProducts"
                                            item-text="name"
                                            item-value="id"
                                            v-model="$v.product.category_products_id.$model"
                                            :error-messages="categoryProductsIdErrors"
                                            >
                                            </v-select>
                                        </v-col>
                                        <v-col
                                        cols="4"
                                        >
                                            <v-select
                                            label="Marca"
                                            :items="brands"
                                            item-text="name"
                                            item-value="id"
                                            v-model="$v.product.brand_id.$model"
                                            :error-messages="brandIdErrors"
                                            >
                                            </v-select>
                                        </v-col>
                                        <v-col
                                        cols="4"
                                        >
                                            <v-select
                                            label="Unidad de medida"
                                            :items="unitMeasurements"
                                            item-text="name"
                                            item-value="id"
                                            v-model="$v.product.unit_measurements_id.$model"
                                            :error-messages="unitMeasurementsIdErrors"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                        cols="6"
                                        >
                                            <v-text-field
                                            label="ISBN"
                                            v-model="product.isbn"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="6"
                                        >
                                            <v-text-field
                                            label="Autor"
                                            v-model="product.author"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-select
                                            label="Cuenta contable"
                                            :items="accounts"
                                            item-text="description"
                                            item-value="id"
                                            v-model="$v.product.account_id.$model"
                                            :error-messages="accountIdErrors"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="dialog = false"
                                >
                                    Cancelar
                                </v-btn>
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="submit"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </template>

        <v-fab-transition>
            <v-btn
            color="pink"
            dark
            fixed
            bottom
            right
            fab
            @click="dialog = true"
            v-can="'products-create'"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-fab-transition>

        <QuestionAlert
        :alert="onAlert"
        question="¿Esta seguro de eliminar el producto?"
        @close="onAlert = false"
        @execute="deleteItem"
        />
    </section>
</template>

<script>
import {mapMutations} from "vuex"
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import QuestionAlert from '@/components/QuestionAlert.vue'

export default {
    mixins: [validationMixin],
    data(){
        return {
            onAlert: false,
           search: '',
           headers: [
               {
                   text: 'Nombre',
                   value: 'name'
               },
               {
                   text: 'Familia',
                   value: 'family_product.name'
               },
               {
                   text: 'Código cuenta contable',
                   value: 'account.code'
               },
               {
                   text: 'Cuenta contable',
                   value: 'account.description'
               },
               {
                   text: 'Acciones',
                   value: 'actions',
                   width: '5%',
                   sortable: false
               },
           ],
           products: [],
           product: {
               id: null,
               family_product_id: null,
               category_products_id: null,
               unit_measurements_id: null,
               brand_id: null,
               account_id: null,
               name: '',
               isbn: null,
               author: null,
               status: null,
           },
           dialog: false,
           familyProducts: [],
           subFamilyProducts: [],
           categoryProducts: [],
           unitMeasurements: [],
           brands: [],
           accounts: [],
           subFamily: null,
           loadSubfamily: false,
           itemToDelete: null
       }
    },
    validations: {
        product: {
            family_product_id: {required},
            category_products_id: {required},
            unit_measurements_id: {required},
            brand_id: {required},
            account_id: {required},
            name: {required}
        },
    },
    computed: {
        dialogTitle() {
            return (this.product.id != null) ? "Editar producto" : "Nuevo producto"
        },
        nameErrors () {
            const errors = []
            if (!this.$v.product.name.$dirty) return errors
            !this.$v.product.name.required && errors.push('Campo requerido')
            return errors
        },
        familyProductIdErrors () {
            const errors = []
            if (!this.$v.product.family_product_id.$dirty) return errors
            !this.$v.product.family_product_id.required && errors.push('Campo requerido')
            return errors
        },
        categoryProductsIdErrors () {
            const errors = []
            if (!this.$v.product.category_products_id.$dirty) return errors
            !this.$v.product.category_products_id.required && errors.push('Campo requerido')
            return errors
        },
        unitMeasurementsIdErrors () {
            const errors = []
            if (!this.$v.product.unit_measurements_id.$dirty) return errors
            !this.$v.product.unit_measurements_id.required && errors.push('Campo requerido')
            return errors
        },
        brandIdErrors () {
            const errors = []
            if (!this.$v.product.brand_id.$dirty) return errors
            !this.$v.product.brand_id.required && errors.push('Campo requerido')
            return errors
        },
        accountIdErrors () {
            const errors = []
            if (!this.$v.product.account_id.$dirty) return errors
            !this.$v.product.account_id.required && errors.push('Campo requerido')
            return errors
        }
    },
   methods: {
        ...mapMutations(['setLayout','setNotify','setLoading']),
        get() {
            this.setLoading(true)

            this.axios.get('/products')
            .then( (response) => {
                this.setLoading(false)

                this.products = response.data.products
                this.unitMeasurements = response.data.unitMeasurements
                this.familyProducts = response.data.familyProducts
                this.brands = response.data.brands
                this.categoryProducts = response.data.categoryProducts
                this.accounts = response.data.accounts
            })
            .catch( (error) => {
                this.setLoading(false)
                console.log(error)
            })
        },
        submit() {
            this.$v.$touch()

            if(!this.$v.$invalid){
                if(this.product.id == null){
                    this.save()
                }else{
                    this.update()
                }
            }
        },
        save() {
            this.setLoading(true)

            this.product.family_product_id = (this.subFamily != null) ?
                                                this.subFamily :
                                                this.product.family_product_id

            this.axios.post('/products',this.product)
            .then( (response) => {
                this.setLoading(false)
                this.get()
                this.dialog = false

                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})
            })
            .catch( (error) => {
                this.dialog = false
                this.setLoading(false)
                console.log(error)
            })
        },
        editItem(item){
            this.product.id = item.id
            this.product.name = item.name
            this.product.abbreviation = item.abbreviation

            this.product.id = item.id
            this.product.family_product_id = item.family_product_id
            this.product.category_products_id = item.category_products_id
            this.product.unit_measurements_id = item.unit_measurements_id
            this.product.brand_id = item.brand_id
            this.product.account_id = item.account_id
            this.product.name = item.name
            this.product.isbn = item.isbn
            this.product.author = item.author
            this.product.status = item.status
            this.dialog = true
        },
        update(){
            this.setLoading(true)

            this.axios.put('/products/'+this.product.id,this.product)
            .then( (response) => {
                this.setLoading(false)

                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})

                this.get()
                this.dialog = false
            })
            .catch( (error) => {
                this.dialog = false
                this.setLoading(false)
                console.log(error)
            })
        },
        showAlert(item) {
            this.itemToDelete = item
            this.onAlert = true
        },
        deleteItem() {
            this.onAlert = false
            this.setLoading(true)

            this.axios.delete('/products/'+this.itemToDelete.id)
            .then( (response) => {
                this.itemToDelete = null
                this.setLoading(false)
                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})
                this.get()
            })
            .catch( (error) => {
                this.setLoading(false)
                console.log(error)
            })
        },
        closeDialog() {
            this.dialog = false
        },
        getSubFamilies() {
            this.loadSubfamily = true

            this.axios.post('/families/getsubfamilies',{
                'family': this.product.family_product_id
            })
            .then( (response) => {
                this.loadSubfamily = false
                this.subFamilyProducts = response.data.subFamilies
            })
            .catch( (error) => {
                this.loadSubfamily = false
                this.setLoading(false)
                console.log(error)
            })
        }
    },
    created(){
        this.setLayout('main-layout')
    },
    mounted(){
        this.get()
    },
    watch: {
        "dialog": function () {
            if(!this.dialog){
                this.product.id = null
                this.product.family_product_id = null
                this.product.category_products_id = null
                this.product.unit_measurements_id = null
                this.product.brand_id = null
                this.product.account_id = null
                this.product.name = ''
                this.product.isbn = ''
                this.product.author = ''
                this.product.status = ''
                this.$v.$reset()
            }
        },
        "product.family_product_id": function() {
            this.getSubFamilies()
        },
    },
    components: {
        QuestionAlert
    }
}
</script>
